/*Import Adobe Fonts*/
@import url("https://use.typekit.net/kwz7qns.css");

/*Global Styles*/
* {
	box-sizing: border-box;
	margin: 0;
	font-size: 100%;
}

.big-bullet {
	font-size: 22px;
	list-style: none;
	line-height: 3rem;
}

body {
	margin: auto;
	font-family: 'brandon-grotesque', sans-serif;
	color: black;
}

.overall-container {
	margin: 0 auto;
}

.nav-container {
	width: 70%;
	margin: 0 auto;
}

.cover-image {
	height: 900px;
	width: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat; 
	text-align: center;
}

.work-title {
	padding-top: 800px;
	font-size: 80px;
}

.sol-work-title {
	padding-top: 820px;
	font-size: 80px;
}

.tra-work-title {
	padding-top: 840px;
	font-size: 80px;
}

.adv-cover-image {
	background-image: url('./img/advmock.png');
}

.tra-cover-image {
	background-image: url('./img/travelcov.png');
}

.sol-cover-image {
	background-image: url('./img/solmock.png');
	background-position: center top;
	margin-bottom: 20px;
}

.amz-cover-image {
	background-image: url('./img/amzcov.png');
}

.work-container {
	width: 40%;
	margin: 0 auto;
}

.work-container h1{
	text-align: center;
	font-size: 60px;
}

dt {
	color: #000000;
	font-family: mr-eaves-modern, sans-serif;
	font-style: normal;
	font-weight: 800;
	font-size: 26px;
}

h1 {
	font-size: 40px;
	font-weight: 900;
}

h2 {
	font-size: 34px;
	font-weight: 500;
}

.abouth2 {
	font-size: 24px;
}

h3 {
	font-size: 30px;
	font-weight: 300;
}

p {
	font-size: 24px;
	margin: auto;
	font-family: mr-eaves-modern, sans-serif;
	font-weight: 300;
	line-height: 2;
	color: #3b3b3b;
}

strong {
	font-weight: 900;
}

blockquote {
	font-size: 26px;
	font-style: italic;
}

.center {
	color: rgb(207, 47, 47);
	text-align: center;
}

.quote-container {
	display: flex;
	justify-content: center;
}

.quote-source {
	text-align: right;
}

.statement {
	font-size: 36px;
	text-align: center;
	color: rgb(0, 0, 0);
}

colour {
	color: rgb(129, 140, 237);
}

.home-name {
	font-size: 40px;
	font-weight: 600;
}

.home {
	font-size: 30px;
	color: rgb(100, 100, 100);
}

a {
	font-size: 22px;
}

p > a {
	color: #333333;
}

p > a:hover {
	text-decoration: none;
}

.proj-quote {
	font-size: 22px;
	color: rgb(37, 37, 37);
	text-align: center;
}

.workBullet {
	font-size: 22px;
}

.features {
	font-size: 22px;
	list-style: none;
}

/*Navigation*/
.nav-item > a {
	color: #060808;
	font-size: 24px;
	font-weight: 400;
}

.nav-item {
	display: inline-block;
}

.nav-link:hover {
	color: #c2d1ff;
}

.navigation {
	height: 100px;
	margin-top: 15px;
	width: 70%;
	margin: 0 auto;
}

.m-nav-item {
	list-style-type: none;
}

.m-nav-item > a {
	font-size: 32px;
	color: #060808;
	font-weight: 400;
}

.mobileMenuButton {
	width: 2rem;
	height: 2rem;
	position: relative;
	left: 85vw;
	margin-top: 1.5rem;
}

.mobileCloseButton {
	width: 2rem;
	height: 2rem;
	position: absolute;
	top: 1.5rem;
	right: 2rem;
}

.case-div {
	margin: 0 auto;
	width: 80%;
	margin-top: 150px;
}

.case-div h2 {
	text-align: center;
}

.case-div:after {
	content: "";
	display: block;
	margin: 0 auto;
	width: 100%;
	padding-top: 5px;
	border-bottom: 2px solid rgb(0, 0, 0);
}

.fade {
	-webkit-animation: fadein 1s;
	-moz-animation: fadein 1s; 
    -ms-animation: fadein 1s; 
    -o-animation: fadein 1s; 
    animation: fadein 1s;
    animation-fill-mode: forwards;
}

/*Animation*/
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/*Footer*/
footer {
	text-align: center;
	margin-bottom: 20px;
	margin-top: 20px;
}

footer > p {
	display: inline-block;
	font-size: 18px;
}

/*Header*/
.header {
	height: 200px;
	margin-top: 3rem;
	vertical-align: middle;
}

.headerWrapper {
	margin: auto;
	margin-top: 40px;
	text-align: center;
}

/*About Page*/
.aboutHeader {
	margin-top: 50px;
}

.about-container {
	margin: 0 auto;
	width: 50%;
}

.aboutHeaderWrapper {
	flex-basis: 60%;

}

.aboutImage {
	margin-top: 100px;
	height: 100px;
	background-color: yellow;
}

.links{
	margin-bottom: 50px;
}

/*Home Page Navlinks*/
.work:hover {
	color: blue;
	text-decoration: none;
}

.work {
	position: absolute;
}

.coverImage {
	width: 600px;
	height: auto;
}

/*Content Pages*/
.space {
	height: 200px;
}

.headerImage {
	width: 100%;
	height: 500px;
	background-size: cover;
	background-position: center;
}

.contentContainer {
	flex-flow: row wrap;
	justify-content: space-around;
}

.content {
	width: 1000px;
	height: 500px;
	margin-bottom: 7rem;
}

.content:hover {
	opacity: 0.8;
}

.contentTitle {
	border-bottom: 0.5px solid gray;
	border-left: 0.5px solid gray;
}

.info {
	text-align: center;
}

.smallScreen {
	width: 60%;
	height: 60%;
}

.smallScreen2 {
	width: 90%;
	height: 90%;
}

.smallGif {
	width: 20%;
	height: 20%;
	margin-left: 8rem;
}

.exploreScreen {
	width: 20%;
	height: 20%;
}

.smallChallenge {
	width: 70%;
	height: 70%;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.intro-container {
	width: 80%;
	margin: 0 auto;
}

.project {
	padding-left: 32px;
	padding-right: 32px;
	max-width: 100%;
	margin-bottom: 64px;
	flex: 0 0 auto;
	justify-content: center;
	align-items: center;
}

.project-container {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	list-style-type: none;
	padding: 0;
	text-align: left;
	align-items: center;
	margin: auto;
	width: 80%;
}

.project-item-container {
	display: flex;
	flex-flow: column nowrap;
	flex-grow: 1;
	position: relative;
	border-radius: 5px;	
	background: rgb(238, 240, 240) none repeat scroll 0% 0%;
}

.project-item {
	border-top-left-radius: 3px;
	border-top-left-radius: 3px;
}

.project-text {
	display: flex;
	flex-direction: column;
	padding: 16px;
	margin-bottom: 8px;
}

.project-title {
	font-size: 30px;
	font-weight: 900;
	line-height: 38px;
	text-align: left;
	margin: 0px;
	color: rgb(0, 0, 0);
}

.project-info {
	font-size: 22px;
	line-height: 24px;
	text-align: left;
	margin: 2px 0px;
	color: rgb(0, 0, 0);
}

.project-image {
	height: 100%;
	width: 100%;
}

.button-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.gif-content {
	display: flex;
}

.gif-side {
	flex: 1;
	margin-left: 30px;
	width: 20%;
	height: 20%;
}

.gif-side-l {
	flex: 1;
	margin-right: 30px;
	width: 20%;
	height: 20%;
}

.text-side {
	flex: 2;
}

.img-container {
	display: flex;
	flex-wrap: wrap;
}

.flex-img {
	flex: 1;
}

.flex-img-travel {
	flex: 50%;
}

.img-container-travel {
	display: flex;
	justify-content: center;
	flex-wrap: nowrap;
	vertical-align: middle;
}

.cjm {
	width: 80%;
	height: 80%;
}

.link {
	color: rgb(72, 188, 255);
}

.center-container {
	display: flex;
	justify-content: center;
}

.image-75 {
	width: 75%;
	height: 75%;
}

.image-150 {
	width: 150%;
	height: 150%;
}

.image-100 {
	width: 100%;
	height: 100%;
}

.shadow {
	box-shadow: rgba(0, 0, 0, 0.5);
}

.image-50 {
	width: 50%;
	height: 50%;
}

.image-25 {
	width: 25%;
	height: 25%;
}

@media only screen and (max-width: 768px) {
	.content {
		width: 300px;
		height: 150px;
		flex-basis: auto;
	}

	.contentContainer {
		margin-top: 4rem;
	}
	
	.work-container {
		width: 90%;
		margin: 0 auto;
	}

	.gif-content {
		display: block;
	}

	.smallScreen {
		width: 150%;
		height: 150%;
	}

	.smallScreen2 {
		width: 150%;
		height: 150%;
	}

	.smallChallenge {
		width: 150%;
		height: 150%;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	.smallGif {
		width: 100%;
		height: 100%;
		margin-left: 8rem;
	}

	.gif-side {
		flex: 1;
		margin-left: 0px;
		width: 70%;
		height: 70%;
	}
	
	.gif-side-l {
		flex: 1;
		margin-right: 0px;
		width: 70%;
		height: 70%;
	}

	.exploreScreen {
		width: 90%;
		height: 90%;
	}

	body {
		text-align: center;
	}

	.space {
		height: 100px;
	}

	.project {
		padding-left: 16px;
		padding-right: 16px;
		max-width: 100%;
		flex-basis: 100%;
		width: 100%;
		margin-top: 16px;
		margin-bottom: 16px;
		flex: 0 0 100%;
		justify-content: center;
		align-items: center;
	}

	.project-container {
		flex-flow: column wrap;
		width: 100%;
	}

	.sol-image {
		content: url(./img/solstice2.png);
	}

	.blo-image {
		content: url(./img/block2.png);
	}

	.stu-image {
		content: url(./img/study2.png);
	}

	.aid-image {
		content: url(./img/aid2.png);
	}

	.tra-image {
		content: url(./img/tra2.png);
	}

	.adv-image {
		content: url(./img/adv2.png);
	}

	.amz-image {
		content: url(./img/amz2.png);
	}

	.flex-img-travel {
		flex: 100%;
	}

	.img-container-travel {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		vertical-align: middle;
	}

	.image-75, .image-50, .image-25, .image-100 {
		width: 100%;
		height: 100%;
	}

	.cjm {
		width: 100%;
		height: 100%;
	}

	.statement {
		font-size: 24px;
		text-align: center;
		color: rgb(0, 0, 0);
	}

	.image-150 {
		width: 100%;
		height: 100%;
	}

	.cover-image {
		height: 500px;
		width: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat; 
		text-align: center;
	}

	.work-title {
		padding-top: 450px;
		font-size: 60px;
	}

	.about-container {
		margin: 0 auto;
		width: 90%;
	}

	.case-div {
		margin: 0 auto;
		width: 80%;
		margin-top: 150px;
		padding-top: 3rem;
	}
}


